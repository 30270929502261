import * as s from "./styles/globalStyles";
import * as d from "./styles/details.styles";
import { Countdown, RoadMapItem } from "./components";

const Details = () => {
    return( 
        <s.Container flex={1} ai={"center"} style={{textAlign:"center"}}>
            <d.TimerContainer ai={"center"} jc={"center"}>
                <d.HiddenImg src="/config/images/hidden.png" />
                <Countdown date={'12/24/2021'}/>
            </d.TimerContainer>
            <d.RoadmapContainer style={{alignItems:"center", backgroundColor:"#1e1e1e", margin:"15px 0", padding:"20px 10%"}}>
                <d.Header>Roadmap</d.Header>
                <RoadMapItem image="/config/images/santa.png" title=".25 AVAX Christmas Day Giveaway!">    
                    The Cozies reveal is just the beginning and in the season of giving we want to give back to you! A snapshot will be taken after Cozies have been unwrapped 
                    by holders. Addresses that are part of the snapshot will recieve .25 AVAX. Additionally, those addresses 
                    will be entered into our 100 AVAX GIVEAWAY with DOUBLED odds!
                </RoadMapItem>
                <RoadMapItem className="even" image="/config/images/airdrop.png" title="100 AVAX Giveaway!">  
                    We are giving away 100 AVAX to a lucky 20 Cozies holders to celebrate all Cozies being minted! All active holders will be eligible for the random drawing 
                    of 20 holders. All 20 of the selected addresses will recieve 5 AVAX each as a gesture of our appreciation. Holders prior to 12/25/2021 will be entered into 
                    the giveaway with doubled odds!
                </RoadMapItem>
                <RoadMapItem image="/config/images/future.png" title="Early Access to Future Drops!">  
                    Cozie holders will be eligible for any future airdrops and have early access to any upcomming projects!
                </RoadMapItem>
            </d.RoadmapContainer>
        </s.Container>
    );
}

export default Details;