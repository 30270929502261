import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {BrowserRouter as Router,Routes,Route,Link} from "react-router-dom";
import { connect, getBlockchainInit } from "./redux/blockchain/blockchainActions";
import { fetchData, fetchTotalSupply } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import {Navbar} from './components';
import Mint from './mint';
import Details from './details';
import MyCozies from "./my-cozies";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [claimingNft, setClaimingNft] = useState(false);
  // const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const data = useSelector((state) => state.data);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    debugger;
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    toast.info(`Minting your ${CONFIG.NFT_NAME}...`, {theme:"dark"});
    // setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        // setFeedback("Sorry, something went wrong please try again later.");
        // setClaimingNft(false);
        toast.error("Sorry, something went wrong please try again later.", {theme:"dark"})
      })
      .then((receipt) => {
        console.log(receipt);
        // setFeedback(
        //   `WOW, the ${CONFIG.NFT_NAME} is yours! go visit nftrade.com to view it.`
        // );
        toast.success(`WOW, the ${CONFIG.NFT_NAME} is yours! go visit nftrade.com to view it.`, {theme:"dark"});

        // setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      })
      .catch(e => {
          console.log(e);
      });
  };

  const getData = () => {
    if (blockchain.account && blockchain.smartContract) {
      dispatch(fetchData(blockchain.account));
    } 
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const connectAccount = () => {
    dispatch(connect());
    getData();
    setClaimingNft(true);
  }

  const mintOnClick = () => {
    if(claimingNft){
      claimNFTs();
      getData();
    } else {
      toast.info("Please connect your wallet to mint!", {theme:"dark"});
    }
  }

  useEffect(() => {
    getConfig();
    dispatch(getBlockchainInit());
  }, []);

  // useEffect(() => {
  //   getData();
  // }, [blockchain.account]);

  useEffect(() => {
    if(blockchain.smartContract !== null)
      dispatch(fetchTotalSupply(blockchain.smartContract));
  }, [blockchain.smartContract]);

  useEffect(() => {
    if(blockchain.errorMsg) {
      toast.error(blockchain.errorMsg, {theme:"dark"});
    }
    
    if(data.errorMsg)
      toast.error(data.errorMsg, {theme:"dark"});

  }, [blockchain.errorMsg, data.errorMsg])

  return (
    <s.Screen>
      <Router>
        <Navbar connectAccount={connectAccount} blockchain={blockchain} />
        <Routes>
          <Route path="/" element={
            <Mint config={CONFIG} claimingNft data={data} mintAmount={mintAmount} setMintAmount={setMintAmount} mintOnClick={mintOnClick}/>
          }></Route>
          <Route path="/details" element={<Details />}></Route>
          <Route path="/my-cozies" element={<MyCozies blockchain={blockchain}/>}></Route>
        </Routes>
      </Router>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
    </s.Screen>
  );
}

export default App;
