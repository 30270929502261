import styled from 'styled-components';
import * as s from "../../styles/globalStyles";

export const RoadmapImgContainer = styled.div`
    margin-right: 30px;

    &.even{
        margin-right: 0px;
        margin-left: 30px;
    }

    @media (max-width:1000px) {
        &.even{
            margin-right:30px;
            margin-left:0px;
        }
    }
`;

export const RoadmapImg = styled.img`
    width:200px;
    padding:10px;
    border: 3px solid var(--secondary);
    border-radius: 100%;
    background-color:#111;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 11px 2px;

    @media (max-width:1000px) {
        width:100px;
    }
    @media (max-width:576px) {
        width:75px;
    }
`;

export const RoadmapTitle = styled(s.TextTitle)`
    @media (max-width:576px) {
        font-size: 5vw;
    }
`;

export const AccordianImgContainer = styled.div`
    display:none;
    justify-content:end;

    @media (max-width:1000px) {
        display:flex;
    }
`;

export const RoadmapItemContainer = styled.div`
    display:flex;  
    margin-bottom:15px; 

    &:last-of-type{
        margin-bottom:0px;
    }
    
    ${AccordianImgContainer} {
        margin-left: auto;
        order: 2;
    }

    @media (max-width:1000px) {
        flex-direction:column;
    }
    ${s.Container}
`;

export const RoadmapItemRow = styled.div`
    flex:1;

    &.even{
        flex-direction:row-reverse;
    }
    
    @media (max-width:1000px) {
        width:100%;

        &.even{
            flex-direction:row;
        }
    }
`;

export const RoadmapTextContainer = styled.div`
    
    ${s.Container}
`;

export const RoadmapDescription = styled.div`
    margin-top:20px;
    color: var(--primary-text);
    font-size: 16px;
    line-height: 1.6;

    ${s.Container}
`;