import styled from 'styled-components';

export const CountdownContainer = styled.div`
    display:flex;
    flex:1;
    flex-direction: ${({ fd }) => (fd ? fd : "column")};
`;

export const CountdownRow = styled.div`
    display:flex;
    justify-content:space-between;  
    margin: 0 -10px;
    flex:1;
    margin-bottom:15px;
    flex-direction: ${({ fd }) => (fd ? fd : "column")};
    justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
    text-align: ${({ ta }) => (ta ? ta : "cemter")};

    @media (max-width:576px) {
        flex-direction:column;
      }
`;

export const CountdownTitle = styled.span`
    text-transform: uppercase;
    color: var(--secondary);
    font-size: 50px;
    padding:10px;
    justify-content:center;
`;

export const TimerPanelContainer = styled.div`
    flex-basis: 25%;
    padding: 0 10px;
    
    @media (max-width:576px) {
        margin-bottom:10px;
      }
`;

export const TimerPanel = styled.div`
    padding: 10px;
    color: var(--primary-text);
    background-color: var(--secondary-text);
    border-radius:15px;
    display:flex;
    flex-direction:column;

    @media (max-width:576px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
`;

export const TimeValueSpan = styled.span`
    font-size:8vw;

    @media (max-width:576px) {
        padding: 0 10px;
      }
`;

export const TimeLabelSpan = styled.span`
    text-transform: capitalize;
    font-size:30px;

    @media (max-width:576px) {
        font-size:8vw;
        padding: 0 10px;
        text-transform: uppercase;
      }
`;