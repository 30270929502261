import * as s from "./globalStyles";
import styled from "styled-components";

export const MyCoziesLayout = styled(s.Container)`
    padding: 0 10%;
    padding-top:50px;
`;

export const CoziesGridContainer = styled(s.Container)`

`;

export const CoziesGrid = styled.div`
    display:flex;
    flex-direction:row;
    align-self:start;
    margin: 0 -10px;
    flex:0;
    flex-wrap:wrap;
`

export const GridItem = styled.div`
    display:flex;
    flex-basis:25%;
    padding: 0 10px;
    margin: 10px 0;
`;

export const CozieContainer = styled.div`
    display:flex;
    flex-direction:column;
    flex-basis:25%;
    padding: 10px;
    background: #111;
    border: 1px solid var(--secondary);
    border-radius: 10px;

    & img{
        width:200px;
        border-radius:10px;
        margin-bottom:10px;
    }
`;

export const MintDescription = styled(s.TextDescription)`
    font-size:30px;
    line-height:1.2;
`

export const AnonContainer = styled(s.Container)`
    flex-direction:row;
    
    @media (max-width:915px) {
        flex-direction: column;
        
    }
`