import React, { useEffect, useState } from "react";
import * as s from "../../styles/globalStyles";
import * as rm from './roadmapItem.styles'
import { useMatchMedia } from "../../helpers";



const RoadMapItem = (props) => {
    const [showDescription, setShowDescription] = useState(false);
    const isDesktopResolution = useMatchMedia("(min-width:1000px)", true);



    return(
        <rm.RoadmapItemContainer style={{alignItems:"center"}} onClick={() => setShowDescription(!showDescription)}>
            <rm.RoadmapItemRow className={props.className} style={{display:"flex", alignItems:"center"}}>    
                <rm.RoadmapImgContainer className={props.className}>
                    <rm.RoadmapImg src={props.image}/>
                </rm.RoadmapImgContainer>
                <rm.RoadmapTextContainer flex={1} ai={"start"} style={{textAlign:"start"}}>
                    <rm.RoadmapTitle style={{marginRight:30}}>{props.title}</rm.RoadmapTitle>
                    {
                        (isDesktopResolution) && 
                            <s.TextDescription>
                                {props.children}
                            </s.TextDescription>
                    }
                </rm.RoadmapTextContainer>
                <rm.AccordianImgContainer>
                    <s.TextTitle>{ showDescription ? "-" : "+" }</s.TextTitle>
                </rm.AccordianImgContainer>
            </rm.RoadmapItemRow>
            { 
                (!isDesktopResolution && showDescription) &&
                    <rm.RoadmapDescription>{props.children}</rm.RoadmapDescription> 
            }
        </rm.RoadmapItemContainer>
    );
}

export default RoadMapItem;