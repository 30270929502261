import React, { useEffect, useState } from "react";
import * as cd from './countdown.styles'
import * as s from '../../styles/globalStyles';

const calculateTimeLeft = (date) => {
    const difference = +new Date(date + " 13:00:00") - +new Date();

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;
};

const reveal = () => {
    return (
        <cd.CountdownRow fd="column" style={{marginLeft:20}}>
            <cd.CountdownRow jc="start" ta="left">
                <cd.CountdownTitle style={{paddingLeft:0}}>Cozies Revealed!</cd.CountdownTitle>
            </cd.CountdownRow>
            <cd.CountdownRow ta="left">
                <s.TextDescription>
                    Cozies are now revealed! You can check out your Cozies and all of their attributes soon on our My Cozies page. 
                    In the meantime you can check them out at NFTrade to see which Cozies are yours!
                </s.TextDescription>
            </cd.CountdownRow>
            <cd.CountdownRow fd="row" jc="center">
                <s.PrimaryLinkButton border bigFont  href="/my-cozies">VIEW MY COZIES</s.PrimaryLinkButton>
                <s.PrimaryLinkButton border bigFont  href="https://nftrade.com/assets/avalanche/0xbfaac5ed3ac5da3ec9c812a9f25d4d2945afcf75">VIEW ON NFTrade</s.PrimaryLinkButton>
            </cd.CountdownRow>
        </cd.CountdownRow>
    );
}

const Countdown = (props) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.date));
    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {  
        timerComponents.push(
            <cd.TimerPanelContainer key={interval}>
                <cd.TimerPanel>
                    <cd.TimeValueSpan>{timeLeft[interval]}</cd.TimeValueSpan>
                    <cd.TimeLabelSpan>{interval}</cd.TimeLabelSpan>
                </cd.TimerPanel>
            </cd.TimerPanelContainer>
        );
    });

    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft(props.date));
        }, 1000);

        return () => {
            clearInterval(timer);
          };
    });

    return(
            timerComponents.length ?
                <cd.CountdownContainer>
                    <cd.CountdownRow style={{justifyContent:"center"}}>
                        <cd.CountdownTitle>{`REVEAL ON ${props.date}`}</cd.CountdownTitle>
                    </cd.CountdownRow>
                    <cd.CountdownRow fd="row">
                        { timerComponents }
                    </cd.CountdownRow>
                </cd.CountdownContainer>
            :
                <cd.CountdownContainer>
                    {reveal()}
                </cd.CountdownContainer>
    );
}

export default Countdown;