import * as s from "./styles/globalStyles";
import styled from "styled-components";

export const StyledLogo = styled.img`
  margin-bottom:30px;
  width: 400px;
  @media (max-width:576px) {
    width: 350px;
  }
`;

export const CardLogo = styled.img`
  width:100px;
  margin-bottom: 10px;
`;

export const StyledImg = styled.img`
  margin-bottom:30px;
  border: 10px solid var(--secondary);
  border-radius: 5%;
  width: 375px;
  @media (max-width:576px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

export const InfoContainer = styled.div`
  display: flex;
  -moz-column-gap: 61px;
  column-gap: 61px;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 15%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 45px;
  color: var(--secondary-text);
  cursor: pointer;
  width:75px
`;

export const Span = styled.span`
  font-size:48px;
  text-align:center;
  color: var(--secondary);
  padding: 0 30px;
`;

export const CardContainer = styled.div`
  margin-top:15px;
  padding: 25px;
  border-radius: 25px;
  flex: 1 1 0px;
  width:200px;
  background-color: var(--accent);

  &:hover{
    background-color: var(--primary);
`;
export const CardLink = styled.a `
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration:none;
`;
export const MintContainer = styled(s.Container)`
  background-image: url("/config/images/preview.png");
  padding-top:50px;
  padding-bottom:15px;
`;
export const AboutContainer = styled(s.Container)`
  padding:0 25px; 
  background-image: url("/config/images/text_background.png"); 
  background-size: cover;
  background-position:bottom;

  & > div {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    padding-top: 8rem;

    @media (max-width:768px) {
      flex-direction:column;
    }
  }

  & img {
    align-self: flex-end;
    width: 24rem;

    @media (max-width:768px) {
      align-self: center;
    }
  }
`;
export const AboutInfoContainer = styled.div`
  flex-basis:50%;
  margin-left: 3.5rem;
  margin-bottom: 9rem;
  margin-right: 1.5rem;
  text-align:start;

  @media (max-width:768px) {
    margin-bottom: 0;
  }
`;

const Mint = (props) => {
  
    const decrementMintAmount = () => {
      let newMintAmount = props.mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      props.setMintAmount(newMintAmount);
    };
  
    const incrementMintAmount = () => {
      let newMintAmount = props.mintAmount + 1;
      if (newMintAmount > 10) {
        newMintAmount = 10;
      }
      props.setMintAmount(newMintAmount);
    };
    
    return( 
        <s.Container flex={1} ai={"center"}  style={{textAlign:"center"}}>
            <MintContainer ai={"center"}>
              <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
              <s.Container style={{alignItems:"center"}}>
                  <InfoContainer>
                      <div>
                        <StyledImg alt={"selections"} src={"/config/images/selections.gif"} />
                      </div>
                      <div>
                        <s.Panel>
                          <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ marginBottom:15 }}>
                              <StyledRoundButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <Span>{ props.mintAmount }</Span>
                              <StyledRoundButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>                       
                          </s.Container>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.PrimaryButton border bigFont onClick={() => {props.mintOnClick()}}>MINT</s.PrimaryButton>
                          </s.Container>
                        </s.Panel>
                        <s.TextSubTitle
                          style={{
                            textAlign: "center",
                            fontSize: 45,
                            fontWeight: "bold"
                          }}
                        >
                          {props.data.totalSupply} / {props.config.MAX_SUPPLY} MINTED
                        </s.TextSubTitle>
                        <s.TextSubTitle
                          style={{
                            textAlign: "center",
                            fontSize: 45,
                            fontWeight: "bold"
                          }}
                        >
                          {props.config.DISPLAY_COST} {props.config.NETWORK.SYMBOL} EACH
                        </s.TextSubTitle>
                      </div>
                  </InfoContainer>
              </s.Container>
            </MintContainer>
            <s.Container style={{alignItems:"center", backgroundColor:"#1e1e1e", padding:"50px 20px"}} >
              <s.ContainerHeader>Join the Cozies Community</s.ContainerHeader>
              <s.TextDescription style={{padding: 15 }} >We will be verified soon with your help! Support the project by following us on Twitter to get the latest news and by joining the conversation on discord to chat with the community!</s.TextDescription>
              <InfoContainer>
                <CardContainer>
                  <CardLink href="https://twitter.com/CoziesNFT" target="_blank">
                    <CardLogo alt={"twitter logo"} src={"/config/images/twitter_logo.png"} />
                    <s.TextTitle>Follow</s.TextTitle>
                  </CardLink> 
                </CardContainer>
                <CardContainer>
                  <CardLink href="https://discord.gg/SjEHv8VJ4Y" target="_blank">
                    <CardLogo alt={"discord logo"} src={"/config/images/discord_logo.png"} />
                    <s.TextTitle>Join</s.TextTitle>
                  </CardLink>
                </CardContainer>
                <CardContainer>
                  <CardLink href={props.config.MARKETPLACE_LINK} target="_blank">
                    <CardLogo alt={"nftrade logo"} src={"/config/images/nftrade_logo.png"} />
                    <s.TextTitle>Trade</s.TextTitle>
                  </CardLink>
                </CardContainer>
              </InfoContainer>
            </s.Container>
            <AboutContainer ai={"center"}>
              <div>
                <AboutInfoContainer>
                  <s.ContainerHeader>About</s.ContainerHeader>
                  <s.TextDescription style={{marginTop:20}}>
                    Cozies are a collection of 1,000 unique collectible characters with proof of ownership stored on the Avalanche C-Chain. These Cozies are the first ugly holiday sweater themed NFT collection 
                    on the Avalanche network.
                  </s.TextDescription>
                  <s.TextDescription style={{marginTop:20}}>
                    Each Cozie is a uniquely generated character that can be officially owned by a single person on Avalanche! Owners will gain access to future collection releases, and be eligible for 
                    future airdrops paid in AVAX! Cozies are unique to their varying attributes. Distinguished by their backgrounds, hats, glasses, sunglasses, goggles, and of course... their sweaters! 
                  </s.TextDescription>
                  <s.TextDescription style={{marginTop:20}}>
                    Try not to wait too long... The limited supply means they will go fast! Mint yours now before the supply runs out and secure your spot in history today!
                  </s.TextDescription>
                </AboutInfoContainer>
                <img alt={"About Cozie"} src={"/config/images/about_cozie.png"} />
              </div>
            </AboutContainer>
        </s.Container>

    )
}
export default Mint;