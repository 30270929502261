import styled from "styled-components";
import { Link } from 'react-router-dom';

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const PrimaryButton = styled.button`
  border-radius:10px;
  background-color: var(--secondary-text);
  padding: ${({big}) => big? '18px 30px' : '10px 28px'};
  font-size: ${({bigFont}) => bigFont? '48px': '18px'};
  border: ${({border}) => border ? `5px solid var(--secondary)`:'none'};
  color: var(--secondary);
  cursor: pointer;

  &:hover{
    background-color: var(--primary); 
  }
`;

export const PrimaryLinkButton = styled.a`
  border-radius:10px;
  background-color: var(--secondary-text);
  padding: ${({big}) => big? '18px 30px' : '10px 28px'};
  font-size: ${({bigFont}) => bigFont? '48px': '18px'};
  border: ${({border}) => border ? `5px solid var(--secondary)`:'none'};
  color: var(--secondary);
  cursor: pointer;
  text-decoration:none;
  margin:20px;

  @media (max-width:915px) {
    margin-left:0px;
    
  }

  @media (max-width:576px) {
    margin-top:0px;
  }

  &:hover{
    background-color: var(--primary); 
    cursor:pointer;
  }

  
`;

export const Button = styled.button`
border-radius: ${({bigRadius}) => bigRadius ? '30px': '20px'};
background-color: ${({primary}) => primary? 'var(--secondary)': 'inherit'};
color: ${({primary}) => primary ? 'var(--secondary-text)': 'var(--secondary)'};
padding: ${({big}) => big? '18px 30px' : '10px 28px'};
font-size: ${({bigFont}) => bigFont? '20px': '18px'};
outline: none;
cursor: pointer;
border: ${({primary}) => primary ? 'none' : '3px solid var(--secondary)'};
transition: all .5s ease;

&:hover{
    background-color: ${({primary}) => primary? '#fff': 'var(--secondary)'};  
    color: #000;
}
&:active{
    transform: translateY(.5rem);
}

@media only screen and (max-width:1000px) {
    /* width: 100%; */
    padding: ${({big}) => big? '18px 30px' : '10px 20px'};
}
@media only screen and (max-width:375px) {
    padding: ${({big}) => big? '12px 20px' : '10px 20px'};
    font-size: ${({bigFont}) => bigFont? '16px': '18px'};
}


`;

export const OutlineButton = styled.button`
border-radius: ${({bigRadius})=> bigRadius? '40px' : '30px'};
border: 2px solid #333;
color: #333;
outline: none;
padding: ${({big}) => big? '15px 60px' : '13px 55px'};
font-size: ${({fontBig}) => fontBig? '22px':'18px'};
transition: all .5s ease;
background-color: #fefefe;


&:hover {
    background-color: #333;
    color: #fff;
    border: none;
    transform: translateY(-.5rem) scale(1.02);
}
&:active{
    transform: translateY(.5rem);
}


@media only screen and (max-width: 1200px) {
    border-radius: ${({bigRadius})=> bigRadius? '20px' : '18px'};
    padding: ${({big}) => big? '9px 30px' : '8px 28px'};
    font-size: ${({fontBig}) => fontBig? '18px':'16px'};
}
`;

export const Panel = styled.div`
  background: rgba(34, 34, 34, .8);;
  padding: 25px 75px;
  border-radius: 10px;
  margin-bottom: 15px;
  @media (max-width:576px) {
    padding: 25px 60px;
  }
`;

export const ContainerHeader = styled(TextTitle)`
  text-transform: uppercase;
  color:var(--secondary);
`;