import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { BiMenu, BiX } from "react-icons/bi";
import {Button} from '../../styles/globalStyles';
import 
{
    Nav, NavbarContainer, Menu, MenuIcon,
    MenuItem, MenuLink, NavButtonContainer
} from './navbar.styles';

const Navbar = (props) => {
//click is the initial state and setclick will be the update state
    const [click, setClick] = useState(false);

//Create a function to handle the click state of the menu icon. 
//if the menu icon was the menu bar at the beginning when clicked it will have the close icon
    const handleClick = () => setClick(!click);

    return (
        <header className="navbar header-fixed">
            <div className="container">
                <IconContext.Provider value={{ color: '#fff'}}>
                    <Nav>
                        <NavbarContainer>
                            <MenuIcon onClick={handleClick}>
                                {click ? <BiX/> : <BiMenu/>}
                            </MenuIcon>

                            <Menu onClick={handleClick} click={click}>
                                <MenuItem>
                                    <MenuLink to="/">MINT</MenuLink>
                                </MenuItem>
                                <MenuItem>
                                    <MenuLink to="/details">DETAILS</MenuLink>
                                </MenuItem>
                                <MenuItem>
                                    <MenuLink to="/my-cozies">MY COZIES</MenuLink>
                                </MenuItem>
                            </Menu>
                            <NavButtonContainer>
                                {props.blockchain.account === "" ||
                                    props.blockchain.account === null ? (
                                    <Button 
                                        onClick={() => {
                                            props.connectAccount()
                                        }}
                                    >CONNECT WALLET</Button>
                                    ) : (
                                        <Button primary>CONNECTED</Button>
                                    )
                                }
                            </NavButtonContainer>
                        </NavbarContainer>


                    </Nav>
                </IconContext.Provider>
            </div>
        </header>
    )
}

export default Navbar;