// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";

const initContractSuccess = (payload) => {
  return {
    type: "CONTRACT_INIT",
    payload: payload
  };
}

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abi = await loadAbiJson();
    const CONFIG = await loadConfigJson();
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      //Web3EthContract.setProvider(ethereum);
      //let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID && ethereum.chainId === CONFIG.NETWORK.CHAIN_ID) {
          let web3 = new Web3(ethereum);
          const smartContract = new web3.eth.Contract(abi, CONFIG.CONTRACT_ADDRESS)
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: smartContract,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const getBlockchainInit = () => {
  return async (dispatch) => {
    const abi = await loadAbiJson();
    const CONFIG = await loadConfigJson();
    let web3 = new Web3(CONFIG.RPC_URL);
    const contract = new web3.eth.Contract(abi, CONFIG.CONTRACT_ADDRESS)
    dispatch(initContractSuccess({smartContract: contract}))
  }
}

async function loadAbiJson() {
  return fetch("/config/abi.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
      }).then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
        })
    ).then(res => {
        return res.data;
    }))
          .catch(error => {
          console.log(error);
          throw error;
      });
}
async function loadConfigJson() {
  return fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
      }).then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
        })
    ).then(res => {
        return res.data;
    }))
          .catch(error => {
          console.log(error);
          throw error;
      });
}