// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchWalletAssetsSuccess = (payload) => {
  return {
    type:"CHECK_WALLET_ASSETS_SUCCESS",
    payload: payload
  }
}

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchTotalSupply = () => {
  return async (dispatch) => {

    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      
        dispatch(
          fetchDataSuccess({
            totalSupply
          })
        );

    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  }
}

export const fetchWalletAssets = () => {
  return async (dispatch) => {

    try {
      const assets = [];
      let blockchain = store.getState().blockchain;
      const account = blockchain.account,
            contract = blockchain.smartContract;

      let isRevealed = await contract.methods.revealed().call();
      let ids = await contract.methods.walletOfOwner(account).call();
      
      for (const id of ids) {
        const asset = await getAssetMetadata(contract,id);
        assets.push(asset);
      }

      dispatch(
        fetchWalletAssetsSuccess({
          isRevealed,
          assets
        })
      );

    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  }
}

const getAssetMetadata = async(contract, id) => {
  var assetUri = await contract.methods.tokenURI(id).call();
  const uri = await fetch(`https://ipfs.io/ipfs/${assetUri.replace('ipfs://','')}`);
  return await uri.json();
}
