import styled from 'styled-components';
import * as s from "./globalStyles";

export const HiddenImg = styled.img`
    width: 300px;
    margin-right: 20px;
    border-radius: 15px;
    flex-basis:25%;

    @media (max-width:1000px) {
        width: 250px;
    }
    @media (max-width:930px) {
        width: 70%;
        margin-right:0px;
    }
    @media (max-width:620px) {
        width: 100%;
    }
`;

export const TimerContainer = styled.div`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ fd }) => (fd ? fd : "column")};
    justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
    align-items: ${({ ai }) => (ai ? ai : "flex-start")};
    background-color: ${({ test }) => (test ? "pink" : "none")};
    width: 100%;
    background-image: ${({ image }) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
    text-align:center;
    flex-direction:row;
    margin-bottom:15px;
    padding:0 10%;
    padding-top:50px;

    @media (max-width:930px) {
        flex-direction:column-reverse;
    }
`;

export const RoadmapContainer = styled.div`
    
    ${s.Container}
`;

export const Header = styled(s.TextTitle)`
    color: var(--secondary);
    font-size: 50px;;
    text-transform: uppercase;
    margin-top:10px;
    margin-bottom: 30px;
`;