import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletAssets } from "./redux/data/dataActions";
import { ipfsExtractor } from "./helpers";
import styled from "styled-components";
import * as s from "./styles/globalStyles";
import * as mc from "./styles/my-cozies.styles"

const MyCozies = (props) => {
    const dispatch = useDispatch();
    const isRevealed = useSelector((state) => state.data.isRevealed);
    const assets = useSelector((state) => state.data.assets);

    useEffect(() => {
        if(props.blockchain.account !== null){
            dispatch(fetchWalletAssets());
        }
      }, [props.blockchain.account]);

    return( 
        <mc.MyCoziesLayout flex={1} ai={"center"} style={{textAlign:"center"}}>
            <s.Container style={{borderBottom:"1px solid var(--accent)", paddingBottom:5, marginBottom:10}}>
                <s.ContainerHeader>My Cozies</s.ContainerHeader>
                <s.TextDescription>View your Cozies</s.TextDescription>
            </s.Container>
            <mc.CoziesGridContainer>
            {
                assets.length ?
                    <mc.CoziesGrid>
                        {
                            assets.map((c, idx) => {
                                const imgSrc = ipfsExtractor(c.image);
                                return(
                                    <mc.GridItem title={c.description} key={idx}>
                                        <mc.CozieContainer>
                                            <img src={imgSrc} />
                                            <s.TextDescription>{c.name}</s.TextDescription>
                                        </mc.CozieContainer>
                                    </mc.GridItem>
                                )
                            })
                        }
                    </mc.CoziesGrid>
                :
                    <mc.AnonContainer flex={1} jc="center" ai="center" style={{alignSelf:"center"}}>
                        <div>
                            <mc.MintDescription>You have no Cozies to view :(</mc.MintDescription>
                            <mc.MintDescription>Let's change that! :)</mc.MintDescription>
                        </div>
                        <s.PrimaryLinkButton border bigFont  href="/" style={{marginTop:20}}>MINT</s.PrimaryLinkButton>
                        
                    </mc.AnonContainer>

            }
            </mc.CoziesGridContainer>
        </mc.MyCoziesLayout>
    );
}

export default MyCozies;